body {
  background-color: #f8fbff;
}

.header {
  margin-bottom: 30px;
  a {
    margin-right: 20px;
    @media (max-width:640px) {
      margin-right: 10px;
    }
  }
}

.content {
  text-align: justify;
  max-width: 700px;
  margin: auto;
  padding: 0 5px;
  line-height: 1.5;
  font-family: 'Raleway', sans-serif;
  color: #3b464e;
}

a:link {
  color: #3b464e;
}

.right {
  float: right;
}

.center {
  text-align: center;
}

.title {
  margin-bottom: 1em;
}

.title h1 {
  margin-block-end: auto;
  font-size: 1.8em;
}

.title span {
  font-size: 0.9em;
  font-style: italic;
}

.intro {
  margin-bottom: -1em;
}

.logos {
  padding: 20px 0 20px 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  a {
    color: transparent;
    margin-right: 40px;
    @media (max-width:960px) {
      margin-right: 3px;
    }
  }

  img {
    max-height: 80px;
    width: auto;

    @media (max-width:400px) {
      max-height: 65px;
    }
  }

  .margin-left {
    margin-left: 50px;
  }
}

img {
  width: 100%;
}

.vertical-img {
  text-align: center;
  img {
    max-height: 500px;
    max-width: 100%;
    width: auto;
  }
}

.post {
  h2 {
    margin-bottom: 0;
    text-align: center;
  }

  .info {
    font-size: 0.8em;
  }
}
